import React from "react"

// Components
import Layout from "../components/layout"
import SEO from "../components/seo"

// SVG
import WebDevelopmentSvg from "../components/svg/home/services/services-web-development-svg"
import MobileDevelopmentSvg from "../components/svg/home/services/services-mobile-development-svg"
import ConsultingSvg from "../components/svg/home/services/services-consulting-svg"

const ServicesPage = () => (
  <Layout>
    <SEO
      title="Services"
      description="Bespoke software development solutions for your business"
    />

    <div className="mt-6 md:mt-23 mx-10 xl:mx-auto mb-14 md:mb-35 max-w-270">
      <h1 className="text-h3 md:text-h1 font-heading font-bold text-black max-w-210">
        Bespoke <span className="text-brand">software development</span>{" "}
        solutions for your business
      </h1>
    </div>

    <div className="py-14 px-10 lg:py-27 xl:px-0 w-full bg-gray-1">
      <div className="md:flex max-w-270 mx-auto">
        <h3 className="md:mr-20 text-h4 md:text-h3 font-heading font-normal text-brand">
          From abstract ideas to tangible products, our team got you covered.
        </h3>
        <div className="mt-6 md:mt-0 text-regular font-paragraph font-normal text-black">
          <p>
            At UnicornBox, we crave technical challenges. We will help you build
            your products as partners from beginning to end.
          </p>
          <p className="mt-7">
            Our team is well-versed in an array of services and technologies and
            they're here to help propel your company and brand to new heights.
            We will work with you to build your idea just like how you imagined
            it... <i>or even better</i>.
          </p>
        </div>
      </div>
    </div>

    <div className="max-w-270 my-20 md:my-27 mx-10 xl:mx-auto grid grid-rows-3 gap-y-20 md:gap-y-37">
      <div id="web-development" className="grid grid-cols-1 md:grid-cols-2">
        <WebDevelopmentSvg className="w-full h-auto" />
        <div className="w-full mt-6 md:my-auto md:pl-22">
          <h3 className="text-h5 md:text-h3 font-heading font-bold text-black">
            Web Development
          </h3>
          <p className="mt-5 text-small md:text-regular font-paragraph font-normal text-black">
            We create <b>stunning, fast, secure</b> and{" "}
            <b>highly-scalable web applications</b> tailored exclusively for
            your business goals. It doesn’t matter if you’re building from
            scratch or revamping an existing one, we’ll <b>make it happen</b>.
          </p>
          <div className="mt-7 grid gap-7">
            {/* <div>
                <p className="text-smallHeading font-paragraph font-medium tracking-widest text-black">
                  Projects
                </p>
                <p className="text-small font-paragraph font-medium text-brand">
                  Digital Ocean, Amazon, Google, Adobe
                </p>
              </div> */}
            <div>
              <p className="text-smallHeading font-paragraph font-medium tracking-widest text-black">
                Technology
              </p>
              <p className="text-small font-paragraph font-bold text-brand">
                Elixir & Phoenix, Ruby on Rails, NodeJS, React, Vue
              </p>
            </div>
          </div>
        </div>
      </div>
      <div id="mobile-development" className="grid grid-cols-1 md:grid-cols-2">
        <MobileDevelopmentSvg className="w-full h-auto md:order-2" />
        <div className="w-full mt-6 md:my-auto md:pr-22">
          <div className="mb-5">
            <h3 className="text-h5 md:text-h3 font-heading font-bold text-black">
              Mobile Development
            </h3>
            <p>
              <span className="text-small font-bold text-red-300">
                Coming soon
              </span>
            </p>
          </div>
          <p className="text-small md:text-regular font-paragraph font-normal text-black">
            Our design and development team will help you build a{" "}
            <b>responsive, secure, and appealing mobile application</b> for the
            people on-the-go.
          </p>
          <div className="mt-7 grid gap-7">
            {/* <div>
              <p className="text-smallHeading font-paragraph font-medium tracking-widest text-black">
                Projects
              </p>
              <p className="text-small font-paragraph font-medium text-brand">
                Marketo, Splitfit, Dribbble, Netflix
              </p>
            </div> */}
            <div>
              <p className="text-smallHeading font-paragraph font-medium tracking-widest text-black">
                Technology
              </p>
              <p className="text-small font-paragraph font-bold text-brand">
                Objective-C, Swift, Java, Kotlin, React Native
              </p>
            </div>
          </div>
        </div>
      </div>
      <div id="consulting" className="grid grid-cols-1 md:grid-cols-2">
        <ConsultingSvg className="w-full h-auto" />
        <div className="w-full mt-6 md:my-auto md:pl-22">
          <h3 className="mb-5 text-h5 md:text-h3 font-heading font-bold text-black">
            Consulting
          </h3>
          <p className="text-small md:text-regular font-paragraph font-normal text-black">
            From <b>choosing the best platform</b> to run your application to{" "}
            <b>identifying and fixing bottlenecks</b> on your existing system or
            even making sure you <b>get the right people</b> for the job, our
            experts are here to help you get on track.
          </p>
          <br />
          <p className="text-small md:text-regular font-paragraph font-normal text-black">
            At UnicornBox, we are <b>huge Elixir fans</b>. Are you planning on
            moving to Elixir-land? We can also help your team get up to speed.
          </p>
          <div className="mt-7">
            <div>
              <p className="text-smallHeading font-paragraph font-medium tracking-widest text-black">
                Consultation Services
              </p>
              <p className="text-small font-paragraph font-medium text-brand">
                System analysis, candidate screening, team training, etc.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default ServicesPage
